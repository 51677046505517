/***
 * Huwess Limited Content Styles
 * By Gavin Hull
***/
main {
    ul {
        li {
            @extend %flower-list;
        }
    }

    .page-blocks {
        position: relative;
        z-index: 10;
        overflow: hidden;

        h2 {
            @extend %field-body;
            text-align: center;
        }

        .field-body,
        %field-body {
            z-index: 10;
            position: relative;
        }

        .field-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .background-image-formatter {
                position: absolute;
                height: 100%;
                height: calc(100% + 84px);
                width: 100%;
                margin-top: -84px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                @media screen and (min-width: 550px) {
                    background-size: calc(100% - 100px) 100%;
                }
            }
        }
    }

    .node-article-with-parallax-bckground {
        margin: -50px 0;

        .field-image {
            height: 400px;
            // height: 50vh;

            .parallax-bg-img {
                position: relative;
                height: 400px;
                // height: 50vh;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }

    .four-boxes { // goes with grad--color__shade in huwess/_base.scss
        h2 {
            text-align: center;
        }

        .container {
            background: image-url('musical-remedies-flower.svg') 50% 50% no-repeat;
            background-size: 190px;
        }

        .one-half.column {
            position: relative;
            min-height: 300px;
            padding: 0 1rem;
            @media screen and (min-width: 550px) {
                width: 50%;
                margin: 0;
            }
            cursor: pointer;

            &:nth-child(even) h3 {
                text-align: right;
            }

            p {
                @extend %centred;
                margin-top: 0;
                text-align: center;
            }
        }
    }

    /***
     * This styles the three image and text box block
    ***/
    .node-thee-title {
        @extend %bark-box-txt;
        z-index: 20;
        position: relative;
        float: left;
        width: 100%;
        padding: 6px 30px 2px;
        margin-right: -100%;
        margin-top: 6rem;

        @media screen and (min-width: 550px) {
            margin-top: 50rem;
            margin-bottom: -50rem;
        }

        @media screen and (min-width: 780px) {
            margin-top: 50vw;
            margin-bottom: -54vw;
        }

        @media screen and (min-width: 1000px) {
            margin-top: 18vw;
            margin-bottom: -20vw;
        }

        @media screen and (min-width: 1400px) {
            margin-top: 15rem;
            margin-bottom: -18rem;
        }
    }

    .node-thee-image-text-box {
        @extend %layout-3col;
        margin-top: -60px;

        figure {
            @extend %layout-3col__col-x;
        }

        .field-body {
            @extend %layout-3col__full;
            @extend %bark-content;
            z-index: 10;
            position: relative;
            width: auto;
            margin: 25px 18px;
            padding: 0 30px;
        }
    }

    /***
     * img-text-reveal has an image with a button ontop and a title underneath
     * the button shows a text box to the right of the box
     * these boxes are laid out in twos next to each other
    ***/
    .img-text-reveal {
        @extend %container;
    }
}
