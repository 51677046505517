/***
*   Huwess Limited Navbar Style
*   By Gavin Hull
*/
$navbar-push: 12 + 12 + 136px;
$navbar-pull: 51px;

.layout-swap__top {
    z-index: 20;
    position: absolute;
    top: 0;
    width: 100%;
    transform: translate(0, -$navbar-pull);

    nav.tb-megamenu { // media queries at the bottom
        width: 85%;
        background: transparent;

        ul.nav {
            display: table;
            width: 100%;
        }

        li,
        .nav > li {
            float: none;
            display: table-cell;
            padding: 0;
            text-align: center;

            &.active {
                > a {
                    background: linear-gradient(180deg, rgba(236, 236, 236, .75) 0%, rgba(236, 236, 236, 0) 100%);
                    color: black;
                    text-shadow: 0 0 5px white;
                }
            }

            > a:hover,
            > a:focus {
                background: linear-gradient(180deg, rgba(236, 236, 236, .95) 0%, rgba(236, 236, 236, .45) 100%);
                color: black;
                text-shadow: none;
            }
        }

        a {
            @extend %h2;
            @extend %mont;
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            line-height: 3.1875rem;
            text-decoration: none;
            text-shadow: 0 0 5px black;
            font-weight: 400;
            color: white;
            background: linear-gradient(180deg, rgba(236, 236, 236, 0) 0%, rgba(236, 236, 236, 0) 100%);
        }

        li.dropdown,
        .nav li.dropdown {

            .dropdown-menu {
                background: linear-gradient(180deg, rgba(236, 236, 236, .75) 0%, rgba(236, 236, 236, 0) 100%),
                            image-url('tree-bark-texture.jpg') left bottom repeat;
                border: 0;

                .mega-inner {
                    padding: 0;

                    a {
                        padding: 0 10px;
                        background: none;
                        border-bottom: 2px solid $white;
                        border-bottom: 2px solid rgba($white, .75);
                        white-space: nowrap;

                        &:hover {
                            background: linear-gradient(180deg, rgba(236, 236, 236, .95) 0%, rgba(236, 236, 236, .45) 100%);
                        }
                    }
                }
            }

            &.active,
            &.open,
            &.active.open {
                > .dropdown-toggle {
                    background: linear-gradient(180deg, rgba(236, 236, 236, .75) 0%, rgba(236, 236, 236, 0) 100%);
                    color: black;
                    text-shadow: 0 0 5px white;
                }

                > a:hover {
                    background: linear-gradient(180deg, rgba(236, 236, 236, .95) 0%, rgba(236, 236, 236, .45) 100%);
                }
            }
        }
        @media screen and (max-width: 1100px) {
            a {
                font-size: 1rem;
            }
        }
        @media screen and (min-width: 800px) {
            transform: translate($navbar-push, 0); // at 800px the logo fits inside the navbar so we push .main-menu
        }
        @media screen and (max-width: 800px) {
            width: 100%;
        }
        @media screen and (max-width: 620px) {
            width: 97%;
            margin: 0 auto;

            ul,
            .nav > li {
                display: block;
            }

            button,
            %menu-button {
                width: 100%;
            }

            .nav-collapse {
                @extend %menu-button;
                background: image-url('tree-bark-texture.jpg') left bottom repeat;

                a {
                    padding: 0;
                    border: 0;
                    font-weight: 400;
                }

                .dropdown-menu {
                    background-color: transparent;

                    a {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}
