/***
 * Huwess Limited Mixins
 * By Gavin Hull
***/
/***
 * bttn-link is a mod of the original mixin used in sparklescakes
 * it takes a color and a light color, which should be similar
 * also takes a text color that should contrast with the first
 * color. 
 * 
 * When clicked the button position goes down as an effect
***/
@mixin bttn-link($color, $light-color, $text-color) {
  display: block;
  color: $text-color;
  padding: 7.5px 45px;
  background: $color;
  border: 0;
  transition: color .2s ease-in, background .2s ease-in;

  &:hover {
    color: invert($text-color);
    text-shadow: 0 0 6px $text-color;
    background: $light-color;
  }

  &:active {
    position: relative;
    top: 1px;
  }
}
/***
 * Icons make a circle with a color inside, which when user hovers 
 * the border inverts its color
 * 
 * This takes a color, a link to an image, and a defaulted border color
***/
@mixin icon-link($color, $img, $border-color: #231f20) {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;

  @media screen and (min-width: 550px) and (max-width: 1280px) {
    width: 200px;
    width: 100%;
    height: 200px;
    height: 16vw;
  }

  background: image-url($img) 50% 50% no-repeat,
              $color;
  background-size: 50%;
  border: 1px solid $border-color;
  border-radius: 50%;
  transition: border .5s ease-in;

  &:hover {
    border-color: invert($border-color);
  }
}
