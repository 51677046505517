/***
 * Slick Slider Styles
 * By: Gavin Hull at Huwess Limited
 * On: 11/07/2016
***/
.slick nav {
    width: 90%;
    margin: 0 5%;

    .slick-arrow {
        background: transparent;
        font-size: 0;

        &:hover::before {
            color: $purple-light;
        }

        &::before {
            position: relative;
            top: -18px;
            left: -21px;
            font-size: 1rem;
            color: $white;
        }
    }

    .slick-prev {
        @extend %icon-arrows;
        @extend %icon-arrow-left;
        width: 54px;
        height: 54px;
    }

    .slick-next {
        @extend %icon-arrows;
        @extend %icon-arrow-right;
        width: 54px;
        height: 54px;
    }
}
