/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.container,
%container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  /* For devices larger than 400px */
  @media (min-width: 400px) {
    width: 85%;
    padding: 0;
  }
  @media (min-width: 550px) {
    width: 80%;
  }
}

.column,
.columns,
%column {
  width: 100%;
  float: left;
  box-sizing: border-box;
}

/* For devices larger than 550px */
@media (min-width: 550px) {
  .column,
  .columns,
  %column {
    margin-left: 4%;
  }

  .column:first-child,
  .columns:first-child {
    margin-left: 0;
  }

  .one.column,
  .one.columns                    { width: 4.66666666667%;
  }

  .two.columns                    { width: 13.3333333333%;
  }

  .three.columns                  { width: 22%;
  }

  .four.columns                   { width: 30.6666666667%;
  }

  .five.columns                   { width: 39.3333333333%;
  }

  .six.columns                    { width: 48%;
  }

  .seven.columns                  { width: 56.6666666667%;
  }

  .eight.columns                  { width: 65.3333333333%;
  }

  .nine.columns                   { width: 74%;
  }

  .ten.columns                    { width: 82.6666666667%;
  }

  .eleven.columns                 { width: 91.3333333333%;
  }

  .twelve.columns                 { width: 100%;
    margin-left: 0;
  }

  .one-third.column               { width: 30.6666666667%;
  }

  .two-thirds.column              { width: 65.3333333333%;
  }

  .one-half.column,
  %one-half                       { width: 48%;
  }

  /* Offsets */
  .offset-by-one.column,
  .offset-by-one.columns          { margin-left: 8.66666666667%;
  }

  .offset-by-two.column,
  .offset-by-two.columns          { margin-left: 17.3333333333%;
  }

  .offset-by-three.column,
  .offset-by-three.columns        { margin-left: 26%;
  }

  .offset-by-four.column,
  .offset-by-four.columns         { margin-left: 34.6666666667%;
  }

  .offset-by-five.column,
  .offset-by-five.columns         { margin-left: 43.3333333333%;
  }

  .offset-by-six.column,
  .offset-by-six.columns          { margin-left: 52%;
  }

  .offset-by-seven.column,
  .offset-by-seven.columns        { margin-left: 60.6666666667%;
  }

  .offset-by-eight.column,
  .offset-by-eight.columns        { margin-left: 69.3333333333%;
  }

  .offset-by-nine.column,
  .offset-by-nine.columns         { margin-left: 78%;
  }

  .offset-by-ten.column,
  .offset-by-ten.columns          { margin-left: 86.6666666667%;
  }

  .offset-by-eleven.column,
  .offset-by-eleven.columns       { margin-left: 95.3333333333%;
  }


  .offset-by-one-third.column,
  .offset-by-one-third.columns    { margin-left: 34.6666666667%;
  }

  .offset-by-two-thirds.column,
  .offset-by-two-thirds.columns   { margin-left: 69.3333333333%;
  }

  .offset-by-one-half.column,
  .offset-by-one-half.columns     { margin-left: 52%;
  }

}

/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Self Clearing Goodness */
.container::after,
.row::after,
.u-cf {
  content: '';
  display: table;
  clear: both;
}

/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.u-full-width {
  width: 100%;
  box-sizing: border-box;
}

.u-max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}

.u-pull-right {
  float: right;
}

.u-pull-left {
  float: left;
}

/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

tr:hover {
  background-color: #f5f5f5;
}

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea,
select {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
}

/* Removes awkward default styles on some inputs for iOS */
input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
        }

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='text']:focus,
input[type='tel']:focus,
input[type='url']:focus,
input[type='password']:focus,
textarea:focus,
select:focus {
  border: 1px solid $colour;
  outline: 0;
}

label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal;
}

