/***
*   Huwess Limited Header Style
*   By Gavin Hull
*/
.header {
  padding-top: 12px;
  padding-bottom: 12px;
  background: image-url('navbar-bg.jpg') left bottom repeat-x,
              image-url('sun_thru_trees.jpg') center top no-repeat;

  @media screen and (max-width: 800px) {
    padding-bottom: 60px;
  }

  &__logo {
    background: rgba(white, .7);
    margin-left: 12px;
  }

  &__name-and-slogan {
    @include visually-hidden;

    h1.header__site-name {
      @extend %inline-sibling;
    }
  }

  &__secondary-menu {
    ul {
      margin: 0;
      max-height: $layout-swap-height;
    }
  }

  $menu-box-border: 1px solid #a6a6a6;

  .block-search { // whole block for the search bar and icon, needing mods in template.php and search-block-form.tpl.php
    @extend %mont;
    position: relative;
    float: right;
    margin-right: 10px;

    @media screen and (max-width: 440px) {
      width: 100%;
      margin: 10px 0;

      form {
        input {
          width: 100%;
        }
      }
    }

    form {
      .form-item-search-block-form { // search bar and input
        margin: 0;

        input {
          padding-left: 5px;
          border: $menu-box-border;
          border-radius: 5px;
          background-color: white;
          text-align: left;
          line-height: 36px;
          color: #0f0f0f;
        }
      }

      .form-actions { // search icon
        position: absolute;
        top: 7px;
        right: 0;
        padding-right: 5px;
        max-height: 26px;
        background-color: rgba(white, .7);
        border-radius: 5px;
      }
    }
  }

  .block-nodeblock {
    @extend %mont;
    position: relative;
    float: right;
    min-height: 40px;
    margin-right: 10px;
    color: white;
    text-decoration: none;

    p {
      margin: 0;
      padding-right: 28px;
      padding-left: 5px;
      background: black;
      border: $menu-box-border;
      border-radius: 5px;
      line-height: 38px;
      color: white;
      text-decoration: none;

      a {
        color: white;
        text-decoration: none;
      }

      img {
        position: absolute;

        &.shopping-cart {
          top: 3px;
          right: 2px;
        }

        &:not(.shopping-cart) {
          top: 0;
          right: -2px;
        }

        &.itunes {
          min-width: 110px;
        }

        &.facebook {
          min-width: 40px;
        }
      }
    }
  }

  #log-in-register-block {
    p {
      padding-right: 5px;
    }

    @media screen and (max-width: 400px) {
      width: 100%;
      margin-top: 10px;
      margin-right: 0;
      text-align: center;
    }
  }

  #block-system-user-menu {
    float: right;  

    > ul {
      margin-top: 0;

      > li {
        @extend %flower-list;

        > a {
          color: $white;
        }
      }
    }
  }
}
