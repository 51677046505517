/***
 * Sections Design for each page
 * By: Gavin Hull
 * At: Huwess Limited
 * On: 18.08.16
***/

.section-bach-musical-remedies {
    @extend %sections;

    h1 {
        position: relative;
        z-index: 20;

        & + .view-albums {
            position: relative;
            z-index: 10;
            margin-top: -2rem;
        }
    }

    #block-views-albums-block {
        & > h2 {
            @extend %bark-box-txt;
            margin: 1.5rem 0 -1px;
            padding-left: 3%;
            line-height: 51px;
        }

        .view-albums {
            @media screen and (min-width: 1000px) {
                width: auto;
            }
        }
    }

    .view-albums {
        @extend %bark-content;
        display: table;
        width: 99%;
        margin-top: 0;
        padding: 0;

        article {
            background: image-url('seperator-icon.png') no-repeat 50% 100%;
        }

        %featured-album-item {
            float: left;
            box-sizing: border-box;
        }

        header {
            @extend %featured-album-item;
            width: 103%;
            margin-left: -1.5%;

            h2 {
                @extend %mont;
                margin: 0;
                padding-left: 3%;
                line-height: 51px;
                text-align: center;
                font-size: 1.3rem;
                font-weight: 400;
                text-transform: uppercase;
                text-rendering: optimizeLegibility;

                a {
                    color: black;
                    text-decoration: none;
                }
            }
        }

        .commerce-product-field-commerce-price {
            @extend %featured-album-item;
            width: 33%;
            margin-top: 1.5rem;
        }

        .commerce-product-field-field-img {
            @extend %featured-album-item;
            width: 63%;
            margin-left: 3%;

            figure {
                margin-bottom: 3rem;
            }
        }

        .field-product-reference {
            @extend %featured-album-item;
            width: 33%;

            input {
                @extend %bttn--green;
                @extend %bark-button;
            }
        }

        .links {
            @extend %links;
        }
    }
}

.section-composing {
    @extend %sections;

    article.node-page .field-body {
        @extend %layout-3col;

        .compo {
            @extend %layout-3col__col-x;

            h4 {
                @extend %icon-arrows;
                width: 100% !important;
                padding: .75rem 1rem;
                box-shadow: $box-shadow;
                cursor: pointer;

                &.opened {
                    @extend %icon-minus-squared;
                }

                &:not(.opened) {
                    @extend %icon-plus-squared;
                }

                &::before {
                    float: right;
                }
            }

            div {
                text-align: center;
            }
        }
    }
}

[class*=' section-teaching'] {
    @extend %sections;

    %icon-container {
        margin-top: 80px;
    }

    %side-icons {
        content: '';
        z-index: 10;
        position: relative;
        float: right;
        left: -100%;
        width: 100px;
        height: 100px;
        margin-top: -58px;
        margin-right: -100%;
    }

    // main > h1 + .node-page {
    //     &::before {
    //         @extend %icon--piano;
    //         @extend %side-icons;
    //     }
    // }

    .day-school {
        @extend %icon-container;

        &::before {
            @extend %icon--school;
            @extend %side-icons;
        }
    }

    .enneagram-links {
        @extend %icon-container;

        &::before {
            @extend %icon--head;
            @extend %side-icons;
        }
    }

    .case-studies-reflections {
        @extend %icon-container;

        &::before {
            @extend %icon--teaching;
            @extend %side-icons;
        }
    }
}

[class*=' section-perform'] {
    @extend %sections;
}

.section-contact {
    @extend %sections;

    h1 {
        position: relative;
        width: calc(100% + 10px);
        margin-right: calc(-100% - 10px);
        margin-left: 0;

        @media screen and (min-width: 550px) {
            width: calc(48% + 10px);
            margin-right: -100%;
        }
    }

    main > form.contact-form {
        margin-top: 70px;

        input.form-submit {
            @extend %bttn--purple;
            @extend %bark-button;
        }
    }

    .half-col-override {
        margin-left: 2%;
    }

    h1,
    form.contact-form,
    .half-col-override {
        @extend %one-half;
        @extend %column;
    }
}
