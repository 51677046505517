/***
 * Huwess Limited Footer Styles
 * By Gavin Hull
***/
footer .page-footer {
    position: relative;

    .field-body {
        position: relative;
        top: 40px;

        img {
            position: absolute;
            right: 20px;
            @media screen and (min-width: 1450px) {
                right: 60px;
            }
        }
    }

    .field-image {
        .background-image-formatter {
            min-height: 350px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }
    }
}
