// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';

// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';

// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 1
//
// Style guide: forms

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 2
//
// Style guide: components

// Navigation
//
// Style guide: components.navigation

@import 'components/autocomplete/autocomplete';
@import 'components/box/box';
@import 'components/breadcrumb/breadcrumb';
@import 'components/button/button';
@import 'components/clearfix/clearfix';
@import 'components/collapsible-fieldset/collapsible-fieldset';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/form-item/form-item';
@import 'components/form-table/form-table';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/more-link/more-link';
@import 'components/nav-menu/nav-menu';
@import 'components/navbar/navbar';
@import 'components/pager/pager';
@import 'components/print-none/print-none';
@import 'components/progress-bar/progress-bar';
@import 'components/progress-throbber/progress-throbber';
@import 'components/resizable-textarea/resizable-textarea';
@import 'components/responsive-video/responsive-video';
@import 'components/skip-link/skip-link';
@import 'components/table-drag/table-drag';
@import 'components/tabs/tabs';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

/***
*   Add user styles
*/
@import 'huwess/styles';
