/***
*   Huwess Limited Base Style
*   By Gavin Hull
*/
// Set the image url path relative to the css dir
$image-url-path-to-components: 'huwess/img';

/***
 * Colours
***/
$green-dark: #4b7b1c;
$green-light: #84c741;
$purple-dark: #701c7b;
$purple-light: #b106c7;
$white: #f5f5f5;

$colour: $green-dark;

/***
 * Variables
***/
$box-shadow: 0 2px 4px rgba(black, .3);

html {
    min-width: 330px;
}

.layout-center,
.flower-bg {
    background: image-url('flower-bg.png') repeat;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat|Bad+Script');

h1,
h2,
%bads {
    font-family: 'Bad Script', cursive;
}

h3,
h4,
h5,
%mont {
    font-family: Montserrat, Arial, sans-serif;
}

a {
    &%alink {
        border-bottom: 0;
        text-decoration: none;
        transition: border .5s ease .1s, color .5s ease .1s, text-shadow .5s ease .1s;

        &:hover,
        &:active {
            color: $purple-light;
            border-bottom: 2px solid rgba($purple-light, .7);
        }
    }

    &:link {
        @extend %alink;
        color: $purple-dark;
    }

    &:visited {
        @extend %alink;
    }
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: .5em 10px;
    quotes: '\201C''\201D''\2018''\2019';

    &::before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: .1em;
        margin-right: .25em;
        vertical-align: -.4em;
    }

    p {
        display: inline;
    }
}

::selection {
    background: $purple-light;
    color: $white;
    text-shadow: none;
    text-decoration: none;
}

::-moz-selection {
    background: $purple-light;
    color: $white;
    text-shadow: none;
    text-decoration: none;
}

table {
    @extend %mont;
}

%flower-list {
    list-style: image-url('musical-remedies-flower-icon-19x19.png');
}

.bttn,
%bttn {
    &--green {
        @include bttn-link($green-dark, $green-light, $white);
    }

    &--purple {
        @include bttn-link($purple-dark, $purple-light, $white)
    }
}

.icon,
%icon {
    &--teaching {
        @include icon-link($green-dark, 'teacher-presentation-music-note.svg');
    }

    &--head {
        @include icon-link($green-light, 'head-black-notes-white.svg');
        background-size: 40%;
    }

    &--piano {
        @include icon-link($purple-light, 'keyboard.svg');
        background-size: 60%;
    }

    &--school {
        @include icon-link($purple-dark, 'day-schools-briefcase-glasses-pencil.svg');
        background-size: 75%;
    }
}

.grad,
%grad {
    // border: 10px solid white;
    padding: 10px;

    &--green {
        &__dark {
            @extend %grad;
            background: linear-gradient(-30deg, rgba($green-dark, 0) 0%, rgba($green-dark, 1) 100%);
            transition: background .5s ease-in;

            &:hover,
            &:focus {
                background: linear-gradient(-30deg, rgba($green-dark, 0) 0%, rgba($green-dark, 1) 100%),
                            white;
            }
        }

        &__light {
            @extend %grad;
            background: linear-gradient(225deg, rgba($green-light, 0) 0%, rgba($green-light, 1) 100%);
            transition: background .5s ease-in;

            &:hover,
            &:focus {
                background: linear-gradient(225deg, rgba($green-light, 0) 0%, rgba($green-light, 1) 100%),
                            white;
            }
        }
    }

    &--purple {
        &__dark {
            @extend %grad;
            background-image: linear-gradient(45deg, rgba($purple-dark, 0) 0%, rgba($purple-dark, 1) 100%);
            transition: background .5s ease-in;

            &:hover,
            &:focus {
                background: linear-gradient(45deg, rgba($purple-dark, 0) 0%, rgba($purple-dark, 1) 100%),
                            white;
            }
        }

        &__light {
            @extend %grad;
            background-image: linear-gradient(135deg, rgba($purple-light, 0) 0%, rgba($purple-light, 1) 100%);
            transition: background .5s ease-in;

            &:hover,
            &:focus {
                background: linear-gradient(135deg, rgba($purple-light, 0) 0%, rgba($purple-light, 1) 100%),
                            white;
            }
        }
    }
}

/***
 * Some useful extensions for centering containers and their blocks
 * Ref: http://www.w3.org/Style/Examples/007/center.en.html#block
 */
// To centre a selector and all its variables CSS3
.centred,
%centred {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

// Vertical CSS2
.centred-old,
%centred-old {
  min-height: 100%; // give height if parent container has none
  display: table-cell;
  vertical-align: middle;
}

// Vertical CSS3
.centred-vert,
%centred-vert {
  height: 100%; // give height if parent container has none
  display: flex;
  align-items: center;

  p,
  img,
  a {
    margin: 0;
  }
}

// Bark BG
.bark-bg,
%bark-bg {
    background: image-url('navbar-bg.jpg') left bottom repeat-x;
}

.bark-box,
%bark-box {
    h1 {
        @extend %bark-box-txt;
        padding: 4px 30px;
    }

    h2 {
        @extend %bark-box-txt;
        padding: 6px 30px 2px;
    }

    .content {
        @extend %bark-content;

        & ~ .links {
            margin-top: -2.5rem;
        }
    }

    input {
        @extend %bttn--green;
        @extend %bark-button;
    }

    .links {
        @extend %links;
    }

    &.even .links a {
        @extend %bttn--green;
    }
}

%bark-box-txt {
    @extend %bark-bg;
    color: $white;
    text-shadow: 0 .5px .5px black;
}

%bark-content {
    margin: -25px 5px 0;
    border: 2px solid rgba(black, .3);
    border-top-color: transparent;
    padding: 0 30px;
    box-shadow: $box-shadow;
    background-color: rgba(white, .3);
}

%bark-button {
    width: 100%;
    margin: 20px 0;
    padding: 7.5px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

%links {
    @extend %featured-album-item;
    width: 33%;
    margin: 0 auto;

    li {
        padding-right: 0;
    }

    a {
        @extend %bttn--purple;
        @extend %bark-button;
    }
}

%sections {
    h1 {
        @extend %bark-box-txt;
        padding: 1.5px 30px;

        & ~ article.node-page,
        & ~ article.node-node-block,
        & ~ form.contact-form {
            @extend %bark-content;
        }
    }

    article.node-page ~ .block-nodeblock,
    form.contact-form ~ .block-nodeblock {
        & > h2 {
            @extend %bark-box-txt;
            padding: 6px 30px 2px;

            ~ .node-teaser,
            ~ .node-node-block {
                @extend %bark-content;

                .links {
                    @extend %links;
                }
            }
        }

        &.odd > h2 ~ .node-teaser .links a {
            @extend %bttn--green;
        }
    }
}
