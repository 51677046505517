/***
 * Styling for jPlayer music player
 * By: Gavin Hull / Huwess Limited
 * On: 04/07/16
***/
$music-green: $green-light; // update with correct hex colour

div.jp-audio {
    width: 100%;
    margin-top: 20px;
}

div.jp-audio div.jp-type-playlist {
    div.jp-interface {
        height: 140px;
        border: 0;

        ul.jp-controls {
            float: left;
            position: relative;
            height: 60px;
            width: 76%;
            margin-top: -40px;
        }

        div.jp-volume-bar {
            float: left;
            position: relative;
            top: 0;
            left: 0;
            width: 20%;
            margin: -3px 0 17.5px;

            .jp-volume-bar-value {
                background: $green-light;
            }
        }
    }

    a {
        background-repeat: no-repeat;
        background: image-url('jplayer-green-monday.png');

        &.jp-play,
        &.jp-play:focus,
        &.jp-play:active {
            background-position: -40px 0;

            &:hover {
                background-position: 0 0;
            }
        }

        &.jp-pause {
            background-position: -40px -42px;

            &:hover {
                background-position: 0 -42px;
            }
        }

        &.jp-stop {
            background-position: -30px -83px;

            &:hover {
                background-position: 0 -83px;
            }
        }

        &.jp-mute {
            background-position: -20px -186px;

            &:hover {
                background-position: 0 -186px;
            }
        }

        &.jp-unmute {
            background-position: -20px -170px;

            &:hover {
                background-position: 0 -170px;
            }
        }

        &.jp-previous {
            background-position: -30px -112px;

            &:hover {
                background-position: 0 -112px;
            }
        }

        &.jp-next {
            background-position: -30px -141px;

            &:hover {
                background-position: 0 -141px;
            }
        }
    }

    div.jp-playlist {
        height: 100px;
        background: image-url('music-player.png') 50% 50%;
        background-size: 100% 100%;

        li {
            display: none;
            border: 0;
            text-align: right;

            &.jp-playlist-current {
                display: block;
            }
        }
    }

    div.jp-progress {
        position: relative;
        width: 100%;
        top: -15px;
        left: 0;

        /***
         * Backgounds here not needed if changing jPlayer's .jpg
        ***/
        .jp-seek-bar,
        %jp-seek-bar {
            background: rgba(black, .7);

            .jp-play-bar,
            %jp-play-bar {
                background: $music-green;
            }
        }
    }

    div.jp-volumn-bar {
        @extend %jp-seek-bar;

        div.jp-volume-bar-volume {
            @extend %jp-play-bar;
        }
    }

    div.jp-current-time,
    div.jp-duration {
        z-index: 20;
        float: left;
        position: relative;
        width: auto;
        top: 0;
        left: 0;
        margin: -15px 2.5% 0;
        font-style: normal;
        color: white;
        line-height: 1em;
    }

    div.jp-duration {
        margin-left: 88%;
    }
}
